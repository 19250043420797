<template>
  <vx-card :title="title">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="my-2" type="border" color="danger" v-on:click="handleClose">
          Back
        </vs-button>
      </div>
    </div>
    <!-- Begin Layout -->

    <!-- 1st row : Leftside Customer Data & Rightside Segmentation Data -->
    <div class="vx-row mt-3">
      <div class="vx-col sm:w-1/2 w-full mb-base">
          <span class="py-2 h5 font-semibold">Customer Data</span><hr>
          <!-- One time cust -->
          <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3">
              One Time
          </div>
          <div class="vx-col sm:w-2/6">
              <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="salesOrder.IsOneTime"
              :disabled="this.update || this.disable_form"
              >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
              </vs-switch>
          </div>
          </div>
          <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Ship to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full mb-6" :value="salesOrder.ShipAddress.Code + ' - ' + salesOrder.ShipAddress.ContactName" disabled/>
              <vs-input class="w-full" :value="salesOrder.ShipAddress.Address" disabled/>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
          </div>
          <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Bill to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full mb-6" :value="salesOrder.BillAddress.Code + ' - ' + salesOrder.BillAddress.ContactName" disabled/>
            <vs-input class="w-full" :value="salesOrder.BillAddress.Address" disabled/>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
          </div>
          <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Customer</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full mb-6" :value="salesOrder.Customer.Code + ' - ' + salesOrder.Customer.Name" disabled/>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
              <!-- <vs-input class="w-full" v-model="salesOrder.Customer.Code" disabled/> -->
              <vs-input class="w-full" v-model="salesOrder.Customer.DisplayName" disabled/>
          </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Customer Name</span>
            </div>
            <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="salesOrder.Customer.Name" disabled/>
            </div>
            <div v-else class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="salesOrder.OcData.name" disabled/>
            </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Created By</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="salesOrder.Creator.Name" disabled/>
            </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Creation Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <!-- <vs-input class="w-full" v-model="salesOrder.DefaultAttribute.CreatedAt" disabled/> -->
                <datepicker
                  format="yyyy - MM - dd"
                  name="date"
                  :inline="false"
                  v-model="salesOrder.DefaultAttribute.CreatedAt"
                  placeholder="Select Date"
                  v-validate="'required'"
                  :disabled=true
              ></datepicker>
            </div>
          </div>
          <!--  -->
          <div v-if="salesOrder.Category == 'ZDCBD'" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Customer Deposits</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                class="selectExample"
                v-model="selectedCustDeposit"
                :options="optionCustDeposit"
                :multiple="true"
                :allow-empty="false"
                track-by="ID"
                :max-height="200"
                placeholder="Type to search"
                :custom-label="customLableCustDeposit"
                :disabled="false"
              />
            </div>
          </div>
          <!-- Customer One time Form -->
          <div v-if="this.isOneTime" class="vx-row mb-2 mt-4">
          <div class="vx-col w-full">
              <span class="py-2 h5 font-semibold">One Time Customer Data</span>
              <!-- <span role="button" class="float-right" @click="handleCloseOt">Close</span> -->
              <hr>
          </div>
          <div class="vx-col w-full">
              <div class="vx-row my-6">
              <div class="vx-col w-full">
                  <label class="vs-input--label">Customer Name <span class="text-danger">*</span></label>
                  <vs-input class="w-full" v-model="oneTimeCust.name" placeholder="Input name" name="Customer Name"/>
              </div>
              </div>
              <address-component
              :data="oneTimeCust.address"
              @data="setDataAddress"
              ></address-component>
              <div class="vx-row mb-6">
              <div class="vx-col w-full">
                  <label class="vs-input--label">Ship / Bill Address <span class="text-danger">*</span></label>
                  <vs-input class="w-full" v-model="oneTimeCust.address_manual" placeholder="Re-input address"/>
              </div>
              </div>
              <div class="vx-row mb-6">
              <div class="vx-col w-full">
                  <label class="vs-input--label">Contact Person <span class="text-danger">*</span></label>
                  <vs-input class="w-full" v-model="oneTimeCust.contact_person" placeholder="Input contact person"/>
              </div>
              </div>
              <div class="vx-row mb-6">
              <div class="vx-col w-full">
                  <label class="vs-input--label">NIK <span class="text-danger">*</span></label>
                  <vs-input type="number" class="w-full" v-model="oneTimeCust.nik" placeholder="Input NIK"/>
              </div>
              </div>
              <div class="vx-row mb-6">
              <div class="vx-col w-full">
                  <label class="vs-input--label">Phone Number <span class="text-danger">*</span></label>
                  <vs-input type="number" class="w-full" v-model="oneTimeCust.phone" placeholder="Input phone number"/>
              </div>
              </div>
              <!-- <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-button size="medium" v-on:click="handleCustOnetime" color="primary">
                    {{ this.oneTimeCust.id != null ? "Update" : "Confirm" }}
                    </vs-button>
                </div>
              </div> -->
          </div>
          </div>
      </div>
      <!-- Segmentation Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
          <span class="py-2 h5 font-semibold">Segmentation Data</span><hr>
          <!-- Territory -->
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" :value="this.salesOrder.Territory.Code + ' ' + this.salesOrder.Territory.Name" disabled/>
            </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Zone</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="salesOrder.ZoneCode + ' | ' + salesOrder.ZoneName" disabled/>
            </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Sales Channel</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" :value="salesOrder.SalesChannel.Name" disabled/>
            </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Distribution Channel</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" :value="this.salesOrder.Customer.CustomerCategory.Name + ' - ' + this.salesOrder.Customer.CustomerCategory.Classification" disabled/>
            </div>
          </div>
          <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Sales ID</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" :value="salesOrder.SalesPersonalCodeExternal + ' - ' + salesOrder.SalesPersonalName" disabled/>
          </div>
          </div>
          <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
              <span>Pricing Group</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
              <multiselect
              v-if="salesOrder.PricingGroupID != 0"
              v-model="pricingGroup.selected"
              :options="pricingGroup.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              :disabled="this.update || this.disable_form"
              >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                  <span class="option__title">
                      {{ props.option.name }} ({{ props.option.code }})
                  </span>
                  </span>
              </template>
              <template slot="option" slot-scope="props">
                  <div class="option__desc">
                  <span class="option__title">
                      {{ props.option.name }} ({{ props.option.code }})
                  </span>
                  </div>
              </template>
              </multiselect>
              <vs-input v-else class="w-full" :value="'-'" disabled/>
          </div>
          </div>
          <!--  -->
          <div class="vx-row my-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Fulfillment Note</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <!-- <vs-input class="w-full" v-model="salesOrder.FulfillmentMessage" :disabled="this.update || this.disable_form"/> -->
              <vs-textarea
                v-model="salesOrder.FulfillmentMessage"
                :disabled="this.update || this.disable_form"
                readonly
              />
            </div>
        </div>
      </div>
  </div>
  <!-- 2nd row : CO Data & Delivery Data -->
  <div class="vx-row">
      <!-- CO Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
      <span class="pt-2 h5 font-semibold">SO Data</span><hr>
      <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
          <span>SO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="salesOrder.Code" disabled/>
          </div>
      </div>
      <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
          <span>Ref. PO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="salesOrder.PoReferenceCode" :disabled="this.disable_form"/>
          </div>
      </div>
      <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
          <span>Ref. SO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="salesOrder.SoReferenceCode" :disabled="this.update || this.disable_form"/>
          </div>
      </div>
      <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
          <span>SO Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="salesOrder.Category" :disabled="this.update || this.disable_form"/>
          </div>
      </div>
      <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- disabledDates -->
          <datepicker
              format="yyyy - MM - dd"
              name="date"
              :inline="false"
              v-model="salesOrder.Date"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="pararelRunDateDisabled"
              :disabled=true
          ></datepicker>
          <!-- :disabled="this.update || this.disable_form" -->
          </div>
      </div>
      <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>PO Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
          <datepicker
              format="yyyy - MM - dd"
              name="date"
              :inline="false"
              v-model="salesOrder.PoReferenceDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="this.disable_form"
          ></datepicker>
          </div>
      </div>
      <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
          <span>Payment Term</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" :value="salesOrder.PaymentTermName" :disabled="this.update || this.disable_form"/>
          </div>
      </div>
      <!--  -->
      </div>
      <!-- Delivery Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
      <span class="pt-2 h5 font-semibold">Delivery Data</span><hr>
       <!--  -->
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Allow Partial</span>
        </div>
        <div class="vx-col vx-row sm:w-2/3 w-full">
          <div class="vx-col sm:w-1/6">
            <input type="radio" value="1" v-model="allowPartialNum" disabled>
            <label> Yes</label>
          </div>
          <div class="vx-col sm:w-1/6">
            <input type="radio" value="0" v-model="allowPartialNum" disabled>
            <label> No</label>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Delivery Type</span>
        </div>
        <div class="vx-col vx-row sm:w-2/3 w-full">
          <vs-input class="w-full" :value="salesOrder.DeliveryTypeName" :disabled="true"/>
        </div>
      </div>
      <!--  -->
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Req. Delivery Date</span>
        </div>
        <div class="vx-col vx-row sm:w-2/3 w-full">
          <datepicker
              format="yyyy - MM - dd"
              class="w-full"
              :inline="false"
              v-model="salesOrder.RequestDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="this.update || this.disable_form"
            ></datepicker>
        </div>
      </div>
      <!--  -->
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Note</span>
        </div>
        <div class="vx-col vx-row sm:w-2/3 w-full">
          <vs-textarea
            v-model="salesOrder.Notes"
            :disabled="false"
            height="110px"
            readonly
          />
        </div>
      </div>
      <!--  -->
      <div class="vx-row my-6">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Flush Out</span>
        </div>
        <div class="vx-col vx-row sm:w-2/3 w-full">
          <div class="vx-col sm:w-1/6">
            <input type="radio" value="1" v-model="flushOut" disabled>
            <label> Yes</label>
          </div>
          <div class="vx-col sm:w-1/6">
            <input type="radio" value="0" v-model="flushOut" disabled>
            <label> No</label>
          </div>
        </div>
      </div>
      </div>
  </div>
  <!-- End layout header data-->
  <!-- Start layout items -->
  <!-- CO Line baru -->
  <div class="vx-row">
    <div class="vx-col w-full">
      <table class="table table-bordered" style="width:100%">
        <thead>
          <tr>
            <th style="vertical-align: middle; width:30%;" rowspan="2">SKU Code</th>
            <th style="vertical-align: middle; width:20%;" rowspan="2" v-if="isExistSkuCombo">Item Combo</th>
            <th style="vertical-align: middle; width:10%;" rowspan="2">HU Label</th>
            <th style="text-align:center; width:15%;" colspan="2">Qty</th>
            <th style="vertical-align: middle; text-align:center;" colspan="4">List Price</th>
            <!-- Hide discount -->
            <th style="vertical-align: middle; text-align:center;" rowspan="2">Discount</th>
            <th style="vertical-align: middle; text-align:center; max-width:70px;" rowspan="2">Rounding</th>
            <th style="vertical-align: middle; text-align:center;" rowspan="2">Tax</th>
            <th style="vertical-align: middle; text-align:center;" rowspan="2">Grand Total</th>
            <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Promotion</th> -->
          </tr>
          <tr>
            <th style="text-align:center;">HU</th>
            <th style="text-align:center;">UOM</th>
            <th style="text-align:center;">Unit</th>
            <th style="text-align:center;">Cogs</th>
            <th style="text-align:center;">Price</th>
            <th style="text-align:center;">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <!-- Start loops item -->
          <!-- Struktur baru, array-of-object -->
                <template v-for="(dt, index) in table.data">
                  <!-- Start for SKU non combo -->
                  <tr :key="index" v-if="(dt.freeItems == undefined || !dt.freeItems) && !isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true))"
                  :class="[ (dt.isBlink && !dt.is_rejected) ? 'selected_parent_fg': (dt.is_rejected ? 'disabled-row' : '') ]"
                  >
                    <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                      <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                      <div :class="''">
                        <b>{{ dt.formInputs[0].value.code != undefined ? '[' + dt.formInputs[0].value.code + '] ' + dt.formInputs[0].value.sku_name : " - " }}</b><br>
                        <!-- <FormInput
                          :components="dt.formInputs[0]"
                          @onSelected="onselected(dt.formInputs[0].value, index)"
                          @search-change="handlerSearchSelect"
                          /> -->
                        <div v-if="!dt.freeItems" class="mt-2">
                          <strong v-if="dt.formInputs[0].value.item_type != undefined && dt.formInputs[0].value.item_type_name != undefined" class="text-success">
                            {{ "SKU Type" }}
                          </strong>
                          <span v-if="dt.formInputs[0].value.item_type != undefined && dt.formInputs[0].value.item_type_name != undefined">
                            : {{ dt.formInputs[0].value.item_type + " || " + dt.formInputs[0].value.item_type_name }}
                          </span>
                        </div>
                        <div v-show="dt.freeItems" class="mt-2">
                          <strong class="text-success"> {{ "Free Items" }} </strong>
                        </div>
                      </div>
                    </td>
                    <td v-if="isExistSkuCombo" style="vertical-align: middle;text-align:center;" :rowspan="dt.list_price.length+dt.rowSpanPlus"> - </td>
                    <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                      <span :key="i" v-for="(dt, i) in dt.hu_labels">
                        {{ dt.unit }}
                      </span>
                    </td>
                    <td v-else style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                      {{ dt.unit }}
                    </td>
                    <td style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                      <!-- LAYOUT GOOD -->
                      <div v-if="!dt.freeItems">
                        <vs-row vs-justify="center">
                          <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :vs-w="dt.colWidth" vs-justify="center" vs-align="center">
                            <vs-input v-model="dt.items_qty_hu[ix]" type="text" @change="calc_itemsLineQtyHU(index, ix)" :disabled="true"></vs-input>
                          </vs-col>
                        </vs-row>
                      </div>
                      <div v-else>
                        <vs-input v-model="dt.items_qty_hu[ix]" type="text" @change="calc_itemsLineQtyHU(index, ix)" :disabled="true"></vs-input>
                      </div>
                    </td>
                    <!-- <td style="vertical-align: middle;"> -->
                      <!-- <vs-input v-model="dt.items_hu" class="w-full" placeholder="" @input="calc_itemsLineQty(index)"/> -->
                    <!-- </td> -->
                    <td v-if="!dt.freeItems" style="vertical-align: middle; padding-right: 25px;" vs-type="flex" vs-justify="center" vs-align="center" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                      {{ dt.items_qty }}
                    </td>

                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                    <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"> - </td>
                  </tr>
                  <!-- List Price -->
                  <!-- diubah jadi template -->
                  <!-- <div :key="index+'~'+i" v-if="!dt.free_item"> -->
                      <template v-for="(lp, i) in table.data[index].list_price">
                        <!-- <tr :key="index+'-'+i" v-on:click="expandDetail(index,i)" class="table-expend"> -->
                        <tr :key="index+'-'+i" class="table-expend" v-if="(!isExistSkuCombo||dt.data_combo?dt.data_combo.length==0:true)&&dt.qty_hu[i]>0">
                          <!-- <td class="table-expend" style="vertical-align: middle; text-align:right;" :rowspan="lp.is_expand? (disable_form ? dt.list_price.length-1 : dt.list_price.length):null" v-on:click="expandDetail(index,i)" > -->
                          <td class="table-expend" style="vertical-align: middle; text-align:right;" :rowspan="lp.is_expand? 2 : null" v-on:click="expandDetail(index,i)" >
                              {{ dt.qty_hu[i] + ' (' + lp.unit + ')'}} <br>
                          </td>
                          <td class="" style="vertical-align: middle; text-align:right;" v-on:click="expandDetail(index,i)" >
                              {{ formatCurrency(lp.cogs) }} <br>
                          </td>
                          <td class="" style="vertical-align: middle; text-align:right;" v-on:click="expandDetail(index,i)" >
                              {{ '@' + formatCurrency(lp.price) }} <br>
                          </td>
                          <td class="" style="vertical-align: middle; text-align:right;" v-on:click="expandDetail(index,i)" >
                              {{ formatCurrency(dt.subtotal.price[i]) }}<br>
                          </td>
                          <td  class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                            <b><a href="javascript:void(0);" v-on:click="showDetailDisc(lp,dt.subtotal.price[i], index, i)"><b>{{formatCurrency(lp.discount)}}</b> <i class="fa fa-eye"></i></a></b>
                          </td>
                          <td style="vertical-align: middle; text-align:center;">
                            <div>
                              <vs-row vs-justify="center">
                                <vs-col vs-justify="center" vs-align="center">
                                  <vs-input :disabled="true" v-model="lp.rounding" type="text" @input="onInputRounding(index, i)" style="max-width: 75px;"></vs-input>
                                </vs-col>
                              </vs-row>
                            </div>
                            <!-- {{ "rounding here" }} -->
                          </td>
                          <td class="" style="vertical-align: middle; text-align:right;" v-on:click="expandDetail(index,i)" >
                            {{ formatCurrency(dt.subtotal.tax[i]) }} <br>
                          </td>
                          <td class="" style="vertical-align: middle; text-align:right;" v-on:click="expandDetail(index,i)" >
                              {{ formatCurrency(dt.grand_total[i]) }} <br>
                          </td>
                        </tr>
                        <tr :key="index+'-'+i" v-else>
                          <td v-if="index+'-'+i == index+'-0' && dt.data_combo.length==0 && table.data[index].is_rejected && !calculated" :colspan="8" style="align-items: center; text-align: center; vertical-align: middle;" :class="[(dt.is_rejected ? 'disabled-row' : '') ]" >
                            {{ "Item rejected." }}
                          </td>
                          <td v-if="index+'-'+i == index+'-0' && dt.data_combo.length==0 && table.data[index].is_rejected && !calculated" :colspan="8" style="align-items: center; text-align: center; vertical-align: middle;">
                            <vs-button size="small" color="warning" @click="doUnReject(index)" >
                              UnReject
                            </vs-button>
                          </td>
                        </tr>
                        <tr :key="'expand-head-'+index+'-'+i" v-if="lp.is_expand && (!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&dt.data_combo?dt.data_combo.length==0:true))">
                          <td colspan="7">
                          <!-- expand-head -->
                            <tr>
                              <th>Width<br>
                                @{{ lp.width }}
                              </th>
                              <th>
                                Height <br>
                                @{{ lp.height }}
                              </th>
                              <th>Length<br>
                                @{{ lp.length }}
                              </th>
                              <th>Volume<br>
                                @{{ lp.volume }}
                              </th>
                              <th>Weight<br>
                                @{{ lp.weight }}
                              </th>
                              <th>Net Weight<br>
                                @{{ lp.net_weight }}
                              </th>
                              <th>Gross Up</th>
                              <th>DPP</th>
                              <th>Price Rule Code</th>
                              <th>Price Rule Valid From</th>
                              <th>Price Rule Valid To</th>
                              <th>Division Code</th>
                              <th>Division Description</th>
                            </tr>
                            <!-- expand-body -->
                            <tr>
                              <td>{{ lp.total_width }}</td>
                              <td>{{ lp.total_height }}</td>
                              <td>{{ lp.total_length }}</td>
                              <td>{{ lp.total_volume }}</td>
                              <td>{{ lp.total_weight }}</td>
                              <td>{{ lp.total_net_weight }}</td>
                              <td>{{ formatCurrency(lp.gross_up) }}</td>
                              <td>{{ formatCurrency(lp.dpp) }}</td>
                              <td>{{ lp.price_rule_code }}</td>
                              <td>{{ lp.price_rule_valid_from }}</td>
                              <td>{{ lp.price_rule_valid_to }}</td>
                              <td>{{ lp.division_code }}</td>
                              <td>{{ lp.division_description }}</td>
                            </tr>
                          </td>
                        </tr>
                      </template>
                  <!-- End of sku non combo -->

                  <!-- Start layout SKU Combo -->
                  <tr :key="index" v-if="!dt.free_item && isExistSkuCombo&&(dt.hu_labels.length>0&&dt.hu_labels[0].is_combo&&dt.data_combo)" :class="[(dt.is_rejected ? 'disabled-row' : '') ]" >
                    <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" 
                      :rowspan="(dt.data_combo?dt.data_combo.length+1:0) + comboRowSpanPlus">
                      <div :class="''">
                        <b>{{ dt.formInputs[0].value.code != undefined ? '[' + dt.formInputs[0].value.code + '] ' + dt.formInputs[0].value.sku_name : " - " }}</b><br>
                        <!-- <FormInput
                          :components="dt.formInputs[0]"
                          @onSelected="onselected(dt.formInputs[0].value, index)"
                          @search-change="handlerSearchSelect"
                        /> -->
                      </div>
                    </td>
                  </tr>

                  <template v-for="(dtCombo,iCombo) in dt.data_combo">
                    <tr :key="index+'-c-'+iCombo" :class="dt.qty_hu[iCombo]>0?'table-expend-combo':''" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.data_combo&&dt.data_combo.length>0)" :rowspan="(dt.data_combo.length>0 ? dt.data_combo.length : 0) + dtCombo.rowSpanPlusCombo">
                      <td :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1" :class="[(dt.is_rejected ? 'disabled-row' : '') ]">
                        [{{ dtCombo.ItemCode }}] {{ dtCombo.ItemName }}
                      </td>
                      <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1" :class="[(dt.is_rejected ? 'disabled-row' : '') ]">
                        <span :key="i" v-for="(dt, i) in dt.hu_labels">
                          {{ dt["unit"] + "." }}{{ dtCombo.UnitName }}
                        </span>
                      </td>
                      <td v-else style="vertical-align: middle;" :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1" :class="[(dt.is_rejected ? 'disabled-row' : '') ]">
                        {{ " - " }}
                      </td>
                      <td style="vertical-align: middle;" :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1" :class="[(dt.is_rejected ? 'disabled-row' : '') ]">
                        <!-- LAYOUT GOOD -->
                        <div>
                          <vs-row vs-justify="center">
                            <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :vs-w="dt.colWidth" vs-justify="center" vs-align="center">
                              <vs-input v-model="dt.items_qty_hu[ix]" type="text" disabled="true"></vs-input>
                              <vs-input v-model="dt.data_combo[iCombo].total_qty" type="text" disabled="true"></vs-input>
                            </vs-col>
                          </vs-row>
                        </div>
                      </td>
                      <td style="vertical-align: middle; padding-right: 25px;" vs-type="flex" vs-justify="center" vs-align="center" v-if="iCombo==0" :rowspan="dt.data_combo.length + comboRowSpanPlus" :class="[(dt.is_rejected ? 'disabled-row' : '') ]">
                        {{ dt.items_qty }}
                      </td>
                      <!-- Batas awal listprice sampai acton for combo -->
                        <!-- <tr :key="index+'-c-'+iCombo" :class="dt.qty_hu[iCombo]>0?'table-expend-combo':''" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.data_combo&&dt.data_combo.length>0)"> -->
                        <template v-if="!dt.is_rejected && dt.items_qty_hu[0] > 0 && !dt.recalculate">
                          <!-- :class="[(dt.is_rejected ? 'disabled-row' : '') ]" -->
                          <td style="vertical-align: middle; text-align:right;" class="combo" v-on:click="expandDetailCombo(index,iCombo)" :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1">
                            {{ dt.list_price.length > 0?dt.data_combo[iCombo].total_qty_combo + ' (' + dt.list_price[iCombo].unit + ')':'' }}
                          </td>
                          <td style="vertical-align: middle; text-align:right;" class="combo" v-on:click="expandDetailCombo(index,iCombo)">
                            {{ dt.list_price.length > 0?formatCurrency(dt.list_price[iCombo].cogs):0 }}
                          </td>
                          <td style="vertical-align: middle; text-align:right;" class="combo" v-on:click="expandDetailCombo(index,iCombo)">
                            {{ '@' + (dt.list_price.length > 0?formatCurrency(dt.list_price[iCombo].price):0) }}
                          </td>
                          <td class="combo" style="vertical-align: middle; text-align:right;" v-on:click="expandDetailCombo(index,iCombo)">
                            {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.price[iCombo]):0 }}
                          </td>
                          <td class="combo" style="vertical-align: middle; text-align:right;" v-on:click="expandDetailCombo(index,iCombo)">
                            <b><a href="javascript:void(0);" v-on:click="showDetailDisc(dt.list_price[iCombo],dt.subtotal.price[iCombo], index, iCombo)"><b>{{formatCurrency(dt.list_price[iCombo].discount)}}</b> <i class="fa fa-eye"></i></a></b>
                          </td>
                          <td class="combo" style="vertical-align: middle;">
                            <div v-if="dt.list_price.length > 0">
                              <vs-row vs-justify="center">
                                <vs-col vs-justify="center" vs-align="center">
                                  <vs-input :disabled="true" v-model="dt.list_price[iCombo].rounding" type="text" @input="onInputRounding(index, iCombo)" style="max-width: 75px;"></vs-input>
                                </vs-col>
                              </vs-row>
                            </div>
                            <!-- {{ "rounding here" }} -->
                          </td>
                          <td style="vertical-align: middle; text-align:right;" class="combo" v-on:click="expandDetailCombo(index,iCombo)">
                            {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.tax[iCombo]):0 }}
                          </td>
                          <td v-if="soType != null && soType.Code == 'ZDSM'"  style="vertical-align: middle; text-align:right;" class="combo" v-on:click="expandDetailCombo(index,iCombo)">
                            {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.disc[iCombo]):0 }}
                          </td>
                          <td style="vertical-align: middle; text-align:right;" class="combo" v-on:click="expandDetailCombo(index,iCombo)">
                            {{ dt.list_price.length > 0?formatCurrency(dt.grand_total[iCombo]):0 }}
                          </td>
                        </template>
                        </tr>
                        <!-- Batas akhir listprice sampai acton for combo -->
                        <!-- <template>
                        </template> -->
                        <tr :key="'expand-head-'+index+'-'+iCombo" v-if="dtCombo.is_expand">
                          <td colspan="6">
                          <!-- expand-head -->
                            <tr>
                              <th>Width<br>
                                @{{ dt.list_price[iCombo].width }}
                              </th>
                              <th>
                                Height <br>
                                @{{ dt.list_price[iCombo].height }}
                              </th>
                              <th>Length<br>
                                @{{ dt.list_price[iCombo].length }}
                              </th>
                              <th>Volume<br>
                                @{{ dt.list_price[iCombo].volume }}
                              </th>
                              <th>Weight<br>
                                @{{ dt.list_price[iCombo].weight }}
                              </th>
                              <th>Net Weight<br>
                                @{{ dt.list_price[iCombo].net_weight }}
                              </th>
                              <th>Price Rule Code</th>
                              <th>Price Rule Valid To</th>
                              <th>Price Rule Valid From</th>
                              <th>Division Code</th>
                              <th>Division Description</th>
                            </tr>
                            <!-- expand-body -->
                            <tr>
                              <td>{{ dt.list_price[iCombo].total_width }}</td>
                              <td>{{ dt.list_price[iCombo].total_height }}</td>
                              <td>{{ dt.list_price[iCombo].total_length }}</td>
                              <td>{{ dt.list_price[iCombo].total_volume }}</td>
                              <td>{{ dt.list_price[iCombo].total_weight }}</td>
                              <td>{{ dt.list_price[iCombo].total_net_weight }}</td>
                              <td>{{ dt.list_price[iCombo].price_rule_code }}</td>
                              <td>{{ dt.list_price[iCombo].price_rule_valid_from }}</td>
                              <td>{{ dt.list_price[iCombo].price_rule_valid_to }}</td>
                              <td>{{ dt.list_price[iCombo].division_code }}</td>
                              <td>{{ dt.list_price[iCombo].division_description }}</td>
                            </tr>
                          </td>
                        </tr>
                    <!-- </tr> -->
                  </template>
                  <!-- End layout SKU Combo -->
                </template>
                <!-- End loop item -->
                <!-- Start FG layout -->
                <!-- row free good -->
                <template v-if="existing_fg">
                  <tr>
                    <td colspan="100%" class="p-0"> <vs-divider><b>Free Good</b></vs-divider></td>
                  </tr>
                  <tr>
                    <th style="vertical-align: middle; width:30%;">SKU Code</th>
                    <th style="vertical-align: middle; width:30%;">Promotion Code</th>
                    <th style="text-align:center; width:15%;">HU</th>
                    <th style="vertical-align: middle; width:10%;">Promotion Type</th>
                    <th style="vertical-align: middle; text-align:center;">Qty</th>
                    <th style="vertical-align: middle; text-align:center;">Cogs</th>
                    <th style="vertical-align: middle; text-align:center;">Price</th>
                    <th style="vertical-align: middle; text-align:center;">Subtotal</th>
                    <th style="vertical-align: middle; text-align:center;">Discount</th>
                    <th style="vertical-align: middle; text-align:center;"></th>
                    <th style="vertical-align: middle; text-align:center;"></th>
                    <th style="vertical-align: middle; text-align:center;"></th>
                    <th style="vertical-align: middle; text-align:center;"></th>
                  </tr>
                  <template v-for="(dt, index) in table.free_goods">
                    <tr :key="index+'-fg'" v-if="!isExistSkuCombo">
                      <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;">
                        <!-- @onSelected="onselected2" -->
                        <FormInput
                            :components="table.free_goods[index].fg_component"
                            :i_data="index"
                            @search-change="handlerSearchSelect"
                          />
                      </td>
                      <td style="vertical-align: middle;" >{{ dt.promotion_code }}</td>
                      <td style="vertical-align: middle;" colspan="1">{{ dt.fg_component.value.item_unit }}</td>
                      <td style="vertical-align: middle;"> {{ dt.promotion_type }}</td>
                      <td style="vertical-align: middle;">{{ dt.fg_component.value.qty }}</td>
                      <td style="vertical-align: middle;">{{ dt.fg_component.value.cogs }}</td>
                      <td style="vertical-align: middle;">{{ formatCurrency(dt.fg_component.value.price) + (dt.fg_component.value.capped?" (capped)":"")}}</td>
                      <td style="vertical-align: middle;">0</td>
                      <td style="vertical-align: middle;">{{ formatCurrency(dt.fg_component.value.discount) }}</td>
                      <td v-if="calculated" style="vertical-align: middle;"> <b><a href="javascript:void(0);" v-on:click="checkFgFrom(table.free_goods[index])"><b>Parent</b> <i class="fa fa-eye"></i></a></b> </td>
                      <td style="vertical-align: middle;"></td>
                      <td style="vertical-align: middle;"></td>
                      <td style="vertical-align: middle;"></td>
                      <td style="vertical-align: middle;"></td>
                    </tr>
                  </template>
                </template>
                <!-- End layout free items -->

                <!-- Info Totals -->
                <tr>
                  <td :colspan="isExistSkuCombo?8:7" style="vertical-align: middle; text-align:right;"> <b>Total</b> </td>
                  <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_netto.toFixed(2)) }}</b> </td>
                  <!-- Hide discount -->
                  <!-- <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_discount.toFixed(2)) }}</b> </td> -->
                  <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_discount.toFixed(2)) }}</b> </td>
                  <td style="vertical-align: middle; text-align:right;"> <b>{{ this.manual_calc_resp.total_rounding.toFixed(2) }}</b> </td>
                  <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_tax.toFixed(2)) }}</b> </td>
                  <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_billing.toFixed(2)) }}</b> </td>
                  <td></td>
                </tr>
        </tbody>
      </table>
    </div>
  </div>
  <vs-popup fullscreen title="Discount Details" :active.sync="detailDisc">
    <div class="vx-row mb-6">
      <div class="vx-col w-full" v-if="detailDisc">
        <discountDetail :selectedDiscount="selectedDiscount" :DetailPricing="detailPricing" :Sku="sku" :calculated="calculated" :PricingCode="pricingCode" :Pricing="formatCurrency(pricing)" :Subtotal="formatCurrency(subtotal)" :Rounding="rounding"></discountDetail>
      </div>
    </div>
  </vs-popup>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
import DiscountDetail from "./discdetail.vue"
import AddressComponent from "@/components/master/Address";
export default {
  components :{
    Datepicker,
    FormInput,
    AddressComponent,
    DiscountDetail,
  },
  props: ["coReqID"],
  data() {
    let id = parseInt(this.$route.params.id);
    // const edit = true;
    if (this.coReqID) {
      id = this.coReqID
    }
    
    return {
      modalReject: {
        activeModal: false,
        activeIndex: 0,
        form: {
          reject_reason: null,
          reject_reason_id:null,
          is_rejected: null
        }
      },
      dispDistChannel: null,
      dispSalesChannel: null,
      dispTerritory: null,
      external: false,
      deliveryOrderCodeIsDisabled: true,
  
      selectTerritory:{},
      selectTax:{
          Name: "",
          Rate: 0
      },
      selectPaymentTerm:{},
      selectDeliveryType:{},
      existing_fg: false,
      resetSalesOrder: {},
      salesOrder: {
          Code: "",
          PoReferenceCode: "",
          DeliveryOrderCode: "",
          ReservationCode: "",
          Customer: {
              ID: 0,
              Name: "",
              Code: "",
          },
          Territorry: {
              Code: ""
          },
          Company: {
              Code: ""
          },
          SalesChannel: {
              Code: "",
              PriceProposal: "Disallow",
          },
          BillToAddressID: 0,
          BillAddress: {
              Address: "",
              City: ""
          },
          ShipmentToAddressID: 0,
          ShipAddress: {
              Address: "",
              City: ""
          },
          Date: "",
          PoReferenceDate: "",
          DueDate: "",
          AllowPartial: false,
          IsFlushOut: false,
          flushOut: 0,
          allowPartialNum: 0,
          Notes: "",
          PaymentTermID: 0,
          SubtotalCharge: 0,
      },
      DueDatePost: "",
  
      priceProposal: "Disallow",
  
      itemsCode: [],
      itemsName: [],
      itemsUom: [],
      itemsQuantityReq: [],
      itemsQuantityAdj: [],
  
      itemsPrice: [],
      itemsPriceCustom: [],
      itemsSubtotal: [],
      itemsDiscount: [],
      itemsTax: [],
      itemsLineTotal: [],
  
      itemsSubtotalEngine: [],
      itemsDiscountEngine: [],
      itemsTaxEngine: [],
      itemsLineTotalEngine: [],
  
      pricingStep: [],
      discountStep: [],
      totalDiscountStep: [],
  
      totalBrutto: 0,
      totalDiscount: 0,
      totalNetto: 0,
      totalTax: 0,
      totalBilling: 0,
      promotionCode:[],
      limitedItem:[],
  
      optionItemAvailable: [{}],
      optionTax: [{}],
      optionPaymentTerm: [{}],
      optionDeliveryType: [{}],
  
      limits: [10],
      limitShow: 10,
      isActive: 1,
      search: "",
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
  
      // CO FORM
      // baseUrl: "/api/sfa/v1/customer-order/create",
      baseUrl: "/api/v3/sales-order/update",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",
      deleteId: null,
      AllOptionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isLoadingCus:false,
      isLoadingSales:false,
      isLoadingShipto:false,
      selectedCustomer: {
        code:"",
        name:""
      },
      selectedBillTo: {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      },
      selectedShippTo: {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      },
      searchCust:"",
      searchItem:"",
      searchSales:"",
      dataCustomerOrder:{
        code:null,
      },
      dataCustomerOrderLine:{},
      table: this.tableDefaultState(),
      dateCo:null,
      duedate:null,
      note:null,
      rDeliveryDate:null,
      pararelRunDateDisabled:{},
      disabledDates: {
          from: new Date(Date.now())
          // to: new Date(Date.now() - 8640000)
        },
      title:"Form Customer-order",
      allowPartial: false,
      isFlushOut: false,
      flushOut: 0,
      allowPartialNum: 0,
      poRefCode: null,
      isOneTime: false,
      sameAddress: false,
      oneTimeCust: {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          countryID: 0,
          provinceName: "",
          provinceID: 0,
          cityName: "",
          cityID: 0,
          transportZone: "",
          districtName: "",
          districtID: 0,
          subdistrictName: "",
          subdistrictID: 0,
          showWarningAddress: false,
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      },
      pricingGroup: {
        selected: null,
        options: [],
        all_options: [],
      },
      update: false,
      disable_form: true,
      calculated: false,
      optionSoType: [],
      soType: null,
      zone: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesChannel: {
        selected: null,
        option: [],
        all_options: [],
      },
      customerCategory: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesPersonal: {
        selected: null,
        option: [],
        all_options: [],
      },
      paymentTerm: {
        selected: null,
        option: [],
        all_options: [],
      },
      deliveryType: {
        selected: null,
        option: [],
      },
      showOTCForm: false,
      CalcRespData: null,
      manual_calc_resp : {
        total_netto: 0,
        total_rounding: 0,
        total_tax: 0,
        total_billing: 0,
        total_discount: 0,
      },
      isExistSkuCombo:false,
      comboRowSpanPlus: 0,
      selectedDiscount: null,
      detailPricing:{},
      detailDisc: false,
      temp_free_good:{
        discount_type:0,
        discount_string:"",
        promotion_type:"",
        promotion_code:"",
        promotion_id: 0,
        promotion_item_req_id: 0,
        promotion_sequence: 0,
        promotion_step_id: 0,
        line:0,
        qty: 0,
        to: 0,
        from: 0,
        base:0,
        base_amount: 0,
        base_string: "",
        fg_component:{
          disabled: false,
          validate: "required",
          name: "fg_sku",
          placeholder: "Sku Code",
          head:"Sku Code",
          type: "multiselect",
          track_by:"item_unit_id",
          isLoading:false,
          multiple: false,
          allowempty: false,
          value: {
            sku_code:"",
            item_id:0,
            item_name:"",
            item_unit:"",
            item_unit_id:0,
            text:"",
            qty:0,
            price:"0",
            discount:0,
            cap:0,
            capped:false,
            cogs:0,
          },
          optionValue:[],
        },
      },
      pricing:"0",
      pricingCode:"",
      sku :"",
      subtotal:"0",
      rounding:"0",
      selectedCustDeposit:[],
      optionCustDeposit:[],
      deposits:[],
      total_deposit:0,
      soID: id,
    }

  },
  methods: {
    customLableCOType({ Code, Description }){
      return `${Code} - ${Description}`;
    },
    expandNonCombo(iCO, iLine){
      let is_expand = this.dataCustomerOrder[iCO].customer_order_line[iLine].is_expand;
      this.dataCustomerOrder[iCO].customer_order_line[iLine].is_expand = !is_expand;
    },
    expandCombo(iCO, iLine, iCombo, data){
      let is_expand = this.dataCustomerOrder[iCO].customer_order_line[iLine].detail_combo[iCombo].is_expand;
      this.dataCustomerOrder[iCO].customer_order_line[iLine].detail_combo[iCombo].is_expand = !is_expand;
    },
    getRowspanCombo(data_combo){
      console.log(data_combo)
      let rowspan = data_combo.length + 1
      data_combo.forEach(el => {
        console.log(el)
        if(el.is_expand) {
          rowspan++;
        }
      });
      return rowspan;
    },
    showOTForm(){
      this.showOTCForm = true;
      // console.log("tampil", this.showOTCForm);
    },
    handleCloseOt() {
      this.showOTCForm = false;
    },
    handleClose() {
      // back to list
      if (this.coReqID) {
        this.$emit("handleClose");
      }else{
        this.$router.push({ name: "manage-canvas-sales" });
      }
    },
    formatCurrency(value) {
      if (value<=0) {
          return "0"
      }
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    
    getTempPromoFG(){
      const data = {
          discount_type:0,
          discount_string:"",
          promotion_type:"",
          promotion_code:"",
          promotion_id: 0,
          promotion_item_req_id: 0,
          promotion_sequence: 0,
          promotion_step_id: 0,
          line:0,
          qty: 0,
          to: 0,
          from: 0,
          base:0,
          base_amount: 0,
          base_string: "",
          fg_component:{
            disabled: false,
            validate: "required",
            name: "fg_sku",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            track_by:"item_unit_id",
            isLoading:false,
            multiple: false,
            allowempty: false,
            value: {
              sku_code:"",
              item_id:0,
              item_name:"",
              item_unit:"",
              item_unit_id:0,
              text:"",
              qty:0,
              price:"0",
              discount:0,
              cap:0,
              capped:false,
              cogs:0,
            },
            optionValue:[],
          }
        }
      return data
    },
    tableDefaultState() {
      return {
        data: [
          {
            id:1,
            formInputs:[
              {
                id:1,
                parentId:1,
                disabled: true,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                isLoading:false,
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
            ],
            items_hu: '0',
            items_qty: '0',
            qty_hu: [],
            hu_labels: [],
            list_price: [],
            reg_disc: [],
            promotion: [],
            tax: [],
            tax_rate: null,
            subtotal: {
              price: [],
              disc: 0,
              tax: [],
              tax_rate: [],
              all_price: 0,
              all_tax: 0,
            },
            colWidth: 0,
          }
        ],
        free_goods:[{
          discount_type:0,
          discount_string:"",
          promotion_type:"",
          promotion_code:"",
          promotion_id: 0,
          promotion_item_req_id: 0,
          promotion_sequence: 0,
          promotion_step_id: 0,
          line:0,
          qty: 0,
          to: 0,
          from: 0,
          base:0,
          base_amount: 0,
          base_string: "",
          fg_component:{
            disabled: false,
            validate: "required",
            name: "fg_sku",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            track_by:"item_unit_id",
            isLoading:false,
            multiple: false,
            allowempty: false,
            value: {
              sku_code:"",
              item_id:0,
              item_name:"",
              item_unit:"",
              item_unit_id:0,
              text:"",
              qty:0,
              price:"0",
              discount:0,
              cap:0,
              capped:false,
              cogs:0,
            },
            optionValue:[],
          }
        }],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getData() {
      return new Promise((resolve, reject) => {
        // this.$http.get("/api/v1/sales-order", {
        this.$http.get("/api/oms/v1/sfa-sales-order/sales-order-mix2", {
            params: {
                sales_order_id: parseInt(this.$route.params.id),
                is_cancel: 0,
            }
        }).then(resp => {
            console.log("resp", resp)
            if (resp.code == 200) {
                console.log("resp.data.SalesOrders[0].PoReferenceDate", resp.data.SalesOrders[0].PoReferenceDate)
                this.salesOrder = resp.data.SalesOrders[0];
                resolve(true);
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                reject(false);
            }
        });
      });
    },
    setDataSO(){
      // show Shipto sesuai customers, keperluan edit Shipto on SO
      // console.log("this.salesOrder", this.salesOrder)
      // console.log("this.salesOrder", this.salesOrder);
      let params = {
        territory_id: this.salesOrder.Territory.DefaultAttribute.ID,
        customer_id: this.salesOrder.CustomerID,
        search: "",
        length: 40,
      }
      
      this.allowPartial = this.salesOrder.AllowPartial == 1 ? true : false;
      this.allowPartialNum = this.salesOrder.AllowPartial;
      this.isFlushOut = this.salesOrder.IsFlushOut;
      this.flushOut = this.salesOrder.IsFlushOut ? 1 : 0;
      if (this.salesOrder.Category != "") {
        this.soType = this.optionSoType.filter(v => {
          return v.Code == this.salesOrder.Category;
        });
        this.soType = this.soType[0];
      } else {
        this.soType = {'name':'COD', 'desc':'Cash Order', 'code':'ZDCO'}; // default saat tidak ada yang match, hardcode ben cantik
      }
      // filter payment term
      this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
        return v.id == this.salesOrder.PaymentTermID;
      });
      this.paymentTerm.selected = this.paymentTerm.selected[0];

      console.log("this.salesPersonal.option", this.salesPersonal.option);
      console.log("this.salesPersonal.selected", this.salesPersonal.selected);

      this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
        return v.ID == this.salesOrder.SalesPersonalID;
      });
      this.salesPersonal.selected = this.salesPersonal.selected[0];
      console.log("this.salesPersonal.option", this.salesPersonal.option);
      console.log("this.salesPersonal.selected", this.salesPersonal.selected);

      // Set pricing group     
      console.log("this.salesOrder.Customer.PricingGroupID", this.salesOrder.Customer.PricingGroupID)
      this.pricingGroup.selected = this.pricingGroup.all_options.filter((v) => {
          return v.ID == this.salesOrder.Customer.PricingGroupID;
      });
      this.pricingGroup.selected = this.pricingGroup.selected[0];

      this.isOneTime = this.salesOrder.IsOneTime || this.salesOrder.OnetimeCustomerID != 0;
      if (this.isOneTime) {
        this.oneTimeCust = {
          id:this.salesOrder.OcData.ID,
          customer:this.salesOrder.Customer,
          name:this.salesOrder.OcData.name,
          address_manual: this.salesOrder.OcData.shipto_address,
          address: {
            address: this.salesOrder.OcData.shipto_address,
            postalCode: this.salesOrder.OcData.postal_code,
            countryName: this.salesOrder.OcData.country,
            countryID: 0,
            provinceName: this.salesOrder.OcData.province,
            provinceID: 0,
            cityName: this.salesOrder.OcData.city,
            cityID: 0,
            transportZone: "",
            districtName: this.salesOrder.OcData.district,
            districtID: 0,
            subdistrictName: this.salesOrder.OcData.sub_district,
            subdistrictID: 0,
            showWarningAddress: false,
          },
          contact_person:this.salesOrder.OcData.contact_person,
          nik:this.salesOrder.OcData.nik,
          phone:this.salesOrder.OcData.phone,
          payment_method_id:this.salesOrder.OcData.payment_method_id,
          tax_id:this.salesOrder.OcData.tax_id,
        };
      }
      
      console.log("this.paymentTerm.selected", this.paymentTerm.selected);
      console.log("this.pricingGroup.selected", this.pricingGroup.selected);

      // Set SO Lines
      this.setSalesOrderLines();
    },
    setSalesOrderLines(){
      console.log("this.salesOrder.Items");
      console.log(this.salesOrder.Items);
      console.log("this.salesOrder.Items");
      console.log(this.salesOrder.Items);

      // Persiapan data lines, extract resp.data.records_line
      let manual_calc_resp = {
        total_netto: 0,
        total_rounding: 0,
        total_tax: 0,
        total_billing: 0,
        total_discount: 0,
      }
      let data = [];
      let fgHolder = [];
      this.table.free_goods = [];

      this.salesOrder.Items.forEach((skus, i) => {
        console.log("skus", skus)
        let tempData = null, detail_hu = {}, iqty = 0, qty_hu = [], items_qty_hu = [];
        let list_price = [], reg_disc = [], tax = [], tax_rate = [], sub_total = [], grand_total = [];

        let this_hu = [];

        if (skus.combo_data == null && !skus.free_item) {
          // Ini persiapan item non combo
          let this_hu = skus.item_units;
          this_hu.forEach((thu, i) => {
            thu.sku_code = skus.lines[0].sku_code;
          });
          skus.lines.forEach((dt) => {
            detail_hu[dt.unit] = dt.unit
          });
          console.log("detail_hu", detail_hu)
          let unit_added = [];
          let is_head = false,ex_head = false
          for (let m = 0; m < this_hu.length; m++) {
            let detail = detail_hu[this_hu[m].unit]
            skus.lines.forEach((dtitems, j) => {
              if ( this_hu[m].unit == dtitems.unit && !unit_added.includes()) {
                unit_added.push(this_hu[m].unit)
                iqty += dtitems.qty_general;
                qty_hu.push(dtitems.qty);
                if (!ex_head&&dtitems.qty>0) {
                  is_head = true
                  ex_head = true
                }
                items_qty_hu.push(dtitems.qty);
                list_price.push({
                  is_head               :is_head,
                  item_name             :dtitems.item_name,
                  sku_code              :dtitems.sku_code,
                  unit                  :dtitems.unit,
                  price                 :dtitems.price.toFixed(2),
                  rounding              :dtitems.rounding,
                  gross_up              :dtitems.gross_up,
                  cogs                  :dtitems.cogs.toFixed(2),
                  height                :dtitems.height,
                  width                 :dtitems.width,
                  length                :dtitems.length,
                  volume                :dtitems.volume,
                  weight                :dtitems.weight,
                  price_rule_code       :dtitems.price_rule_code ? dtitems.price_rule_code : "-",
                  price_rule_id         :dtitems.price_rule_id ? dtitems.price_rule_id : 0,
                  price_rule_valid_to   : dtitems.price_rule_valid_to ? moment(dtitems.price_rule_valid_to).format("YYYY-MM-DD") : " - ",
                  price_rule_valid_from : dtitems.price_rule_valid_from ? moment(dtitems.price_rule_valid_from).format("YYYY-MM-DD") : " - ",
                  division_code         :dtitems.division_code ? dtitems.division_code : " - ",
                  division_description  :dtitems.division_description ? dtitems.division_description : " - ",
                  net_weight            :dtitems.net_weight ? dtitems.net_weight : 0,
                  total_height          :dtitems.total_height ? dtitems.total_height : (dtitems.qty * dtitems.height) ,
                  total_weight          :dtitems.total_weight ? dtitems.total_weight : (dtitems.qty * dtitems.weight),
                  total_width           :dtitems.total_width ? dtitems.total_width : (dtitems.qty * dtitems.width),
                  total_volume          :dtitems.total_volume ? dtitems.total_volume : (dtitems.qty * dtitems.volume),
                  total_length          :dtitems.total_length ? dtitems.total_length : (dtitems.qty * dtitems.length),
                  total_net_weight      :dtitems.total_net_weight ? dtitems.total_net_weight : (dtitems.net_weight ? (dtitems.qty * dtitems.net_weight) : 0),
                  is_expand             :false,
                  is_rejected: false,
                  rejected_reason_id: 0,
                  rejected_reason: "",
                  discount:dtitems.discount.toFixed(2),
                  promo_calculation     : dtitems.so_line_promo,
                });
                is_head = false
                reg_disc.push(dtitems.discount.toFixed(2));
                tax.push(dtitems.tax.toFixed(2));
                tax_rate.push(dtitems.tax_rate);
                let gt = 0;
                if (dtitems.gross_up != undefined && dtitems.gross_up != 0) {
                  gt = dtitems.subtotal + dtitems.tax + dtitems.gross_up - dtitems.discount
                } else {
                  gt = dtitems.subtotal + dtitems.tax - dtitems.discount
                }
                sub_total.push(dtitems.subtotal.toFixed(2));
                grand_total.push(gt.toFixed(2));

                // persiapan Total Semua
                // jika direject, tidak ikut dihitung
                console.log("dtitems", dtitems)
                if (!dtitems.is_rejected) {
                  manual_calc_resp.total_netto += dtitems.subtotal;
                  manual_calc_resp.total_rounding += dtitems.rounding;
                  manual_calc_resp.total_tax += dtitems.tax;
                  manual_calc_resp.total_discount += dtitems.discount;
                  if (dtitems.gross_up != undefined && dtitems.gross_up != 0) {
                    manual_calc_resp.total_billing += (dtitems.subtotal - dtitems.discount + dtitems.tax + dtitems.gross_up);
                  } else {
                    manual_calc_resp.total_billing += (dtitems.subtotal - dtitems.discount + dtitems.tax);
                  }
                }
              } else if (detail == undefined && !unit_added.includes(this_hu[m].unit)){
                unit_added.push(this_hu[m].unit)
                qty_hu.push(0);
                items_qty_hu.push(0);
                list_price.push({
                  item_name :dtitems.item_name,
                  sku_code  :dtitems.sku_code,
                  unit      :this_hu[m].unit,
                  price     :(0).toFixed(2),
                  rounding  :0,
                  gross_up  :(0).toFixed(2),
                  discount  :(0).toFixed(2),
                  promo_calculation : [],
                });
                reg_disc.push((0).toFixed(2));
                tax.push((0).toFixed(2));
                sub_total.push((0).toFixed(2));
                grand_total.push((0).toFixed(2));
              }

              tempData = {
                rowSpanPlus:1,
                items_id:dtitems.item_id,
                data_combo: [],
                id:i+j,
                freeItems:false,
                formInputs:[
                  {
                    id:1,
                    parentId:i+1,
                    disabled: true, //tidak diperbolehkan mengubah existing SKU
                    validate: "required",
                    name: "sku_code",
                    placeholder: "Sku Code",
                    head:"Sku Code",
                    type: "multiselect",
                    multiple: false,
                    allowempty: false,
                    value: {
                      item_unit_id	:	dtitems.item_unit_id,
                      code	        :	dtitems.sku_code,
                      text	        :	'[' + dtitems.sku_code + ']' + dtitems.item_name,
                      sku_name	    :	dtitems.item_name,
                      is_combo      : false,
                      item_type     : dtitems.item_type,
                      item_type_name: dtitems.item_type_name,
                    },
                    optionValue:[],
                  },
                ],
                items_hu: '0',
                hu_labels: this_hu,
                promotion: [],
                tax_rate: dtitems.tax_rate,
                subtotal: {
                  disc: dtitems.discount,
                  all_price: 0,
                  all_tax: 0,
                  tax_rate: [],
                },
                is_rejected: dtitems.is_rejected,
              };


              // // end pecah price
            });
          }
          tempData.items_qty          = iqty;
          tempData.qty_hu             = qty_hu;
          tempData.list_price         = list_price;
          tempData.reg_disc           = reg_disc;
          tempData.tax                = tax;
          tempData.tax_rate           = tax_rate;
          tempData.grand_total        = grand_total;

          tempData.subtotal.price     = sub_total;
          tempData.subtotal.tax       = tax;
          tempData.subtotal.tax_rate  = tax_rate;
          tempData.subtotal.all_price = tempData.subtotal.all_price.toFixed(2);
          tempData.subtotal.all_tax   = tempData.subtotal.all_tax.toFixed(2);
          tempData.items_hu           = tempData.qty_hu.join('.');
          tempData.items_qty_hu       = items_qty_hu;
          tempData.colWidth           = skus.combo_data == null ? (12/tempData.qty_hu.length) : 12;
        } else if (skus.combo_data != null && !skus.free_item) {
          // Ini persiapan item combo
          this.isExistSkuCombo = true;
          // this_hu.push(skus.combo_data.combo_hu)

          this_hu =  {
            allow_sfa: 0,
            amount_uom: skus.combo_data.combo_hu[0].amount_uom,
            batch: "",
            combo_items: [],
            expired_date: null,
            height: skus.combo_data.combo_hu[0].height,
            is_combo: true,
            item_unit_id: skus.combo_data.combo_hu[0].item_unit_id,
            length: skus.combo_data.combo_hu[0].length,
            net_weight: skus.combo_data.combo_hu[0].net_weight,
            sfa_default: skus.combo_data.combo_hu[0].sfa_default,
            sku_code: skus.combo_data.combo_code,
            stock: 0,
            unit: skus.combo_data.combo_hu[0].unit,
            volume: skus.combo_data.combo_hu[0].volume,
            weight: skus.combo_data.combo_hu[0].weight,
            width: skus.combo_data.combo_hu[0].width
          };

          tempData = {
            rowSpanPlus:1,
            data_combo: [],
            id:i,
            formInputs:[
              {
                id:1,
                parentId:i+1,
                disabled: true, //tidak diperbolehkan mengubah existing SKU
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                multiple: false,
                allowempty: false,
                value: {
                  item_unit_id	:	skus.combo_data.combo_hu[0].item_unit_id,
                  code	        :	skus.combo_data.combo_code,
                  text	        :	'[' + skus.combo_data.combo_code + ']' + skus.combo_data.combo_name,
                  sku_name	    :	skus.combo_data.combo_name,
                  is_combo      : true,
                },
                optionValue:[],
              },
            ],
            items_hu: skus.combo_data.quantity_combo,
            items_qty: skus.combo_data.quantity_combo,
            qty_hu: [],
            hu_labels: [],
            promotion: [],
            subtotal: {
              all_price: 0,
              all_tax: 0,
            },
          };
          items_qty_hu.push(parseInt(skus.combo_data.quantity_combo));
          let is_head2 = false, ex_head2 = false
          skus.lines.forEach((dtitems) => {
            if (!ex_head2 && dtitems>0) {
              ex_head2 = true
              is_head2 = true
            }
            let combo = {
                "CompanyID": this.salesOrder.CompanyID,
                "Code": skus.combo_data.combo_code,
                "Name": skus.combo_data.combo_name,
                "ValidFrom": skus.combo_data.valid_from,
                "ValidTo": skus.combo_data.valid_to,
                "ItemUnitID": dtitems.item_unit_id,
                "Quantity": dtitems.qty / dtitems.quantity_combo,
                "Type": "",
                "ItemName": dtitems.item_name,
                "ItemCode": dtitems.sku_code,
                "UnitName": dtitems.unit,
                "ComboID": dtitems.combo_id,
                "ComboName": dtitems.combo_name,
                "ComboCode": dtitems.combo_code,
                "total_qty": dtitems.qty,
                "total_qty_combo": dtitems.qty,
                "rowSpanPlusCombo":1,
                "amount_uom":dtitems.amount_uom,
                "is_expand": false,
            };
            this_hu.combo_items.push(combo);
            tempData.data_combo.push(combo);
            tempData.qty_hu.push(dtitems.qty);

            list_price.push({
              is_head               :is_head2,
              sku_code              :dtitems.sku_code,
              price                 :dtitems.price.toFixed(2),
              item_name             :dtitems.item_name,
              unit                  :dtitems.unit,
              rounding              :dtitems.rounding,
              gross_up              :dtitems.gross_up,
              cogs                  :dtitems.cogs.toFixed(2),
              height                :dtitems.height,
              width                 :dtitems.width,
              length                :dtitems.length,
              volume                :dtitems.volume,
              weight                :dtitems.weight,
              price_rule_code       :dtitems.price_rule_code ? dtitems.price_rule_code : "-",
              price_rule_id         :dtitems.price_rule_id ? dtitems.price_rule_id : 0,
              price_rule_valid_to   :dtitems.price_rule_valid_to ? moment(dtitems.price_rule_valid_to).format("YYYY-MM-DD") : " - ",
              price_rule_valid_from :dtitems.price_rule_valid_from ? moment(dtitems.price_rule_valid_from).format("YYYY-MM-DD") : " - ",
              division_code         :dtitems.division_code ? dtitems.division_code : " - ",
              division_description  :dtitems.division_description ? dtitems.division_description : " - ",
              net_weight            :dtitems.net_weight ? dtitems.net_weight : 0,
              total_height          :dtitems.total_height ? dtitems.total_height : (dtitems.qty * dtitems.height) ,
              total_weight          :dtitems.total_weight ? dtitems.total_weight : (dtitems.qty * dtitems.weight),
              total_width           :dtitems.total_width ? dtitems.total_width : (dtitems.qty * dtitems.width),
              total_volume          :dtitems.total_volume ? dtitems.total_volume : (dtitems.qty * dtitems.volume),
              total_length          :dtitems.total_length ? dtitems.total_length : (dtitems.qty * dtitems.length),
              total_net_weight      :dtitems.total_net_weight ? dtitems.total_net_weight : (dtitems.net_weight ? (dtitems.qty * dtitems.net_weight) : 0),
              is_expand             : false,
              promo_calculation     : dtitems.so_line_promo,
              discount              : dtitems.discount.toFixed(2),
            });
            is_head2 = false
            reg_disc.push(dtitems.discount.toFixed(2));
            tax.push(dtitems.tax.toFixed(2));
            let gt = dtitems.subtotal + dtitems.tax - dtitems.discount + dtitems.gross_up
            sub_total.push(dtitems.subtotal.toFixed(2));
            grand_total.push(gt.toFixed(2));

            // MANUAL CALC
            // persiapan Total Semua
            // jika direject, tidak ikut dihitung
            console.log("dtitems", dtitems)
            if (!dtitems.is_rejected) {
              manual_calc_resp.total_netto += dtitems.subtotal;
              manual_calc_resp.total_rounding += dtitems.rounding;
              manual_calc_resp.total_tax += dtitems.tax;
              manual_calc_resp.total_billing += (dtitems.subtotal - dtitems.discount + dtitems.tax);
              // MANUAL CALC
            }

          });

          tempData.hu_labels.push(this_hu);

          tempData.items_qty          = skus.combo_data.quantity_combo;
          tempData.list_price         = list_price;
          tempData.reg_disc           = reg_disc;
          tempData.tax                = tax;
          tempData.grand_total        = grand_total;

          tempData.subtotal.disc      = reg_disc;
          tempData.subtotal.price     = sub_total;
          tempData.subtotal.tax       = tax;
          tempData.subtotal.tax_rate       = tax_rate;
          tempData.subtotal.all_price = tempData.subtotal.all_price.toFixed(2);
          tempData.subtotal.all_tax   = tempData.subtotal.all_tax.toFixed(2);
          tempData.items_hu           = skus.combo_data.quantity_combo;
          tempData.items_qty_hu       = items_qty_hu;
          tempData.colWidth           = skus.combo_data == null ? (12/tempData.qty_hu.length) : 12;
          tempData.item_id            = skus.item_id;
        } else {
          // ini handle untuk free items
          console.log("skus.free_item", skus.free_item)
          console.log("skus", skus)
          // Part setting freegoods
          const disc_type_choice = 9
          var temp_data_fg = this.getTempPromoFG()
          var temp_fg_select = this.temp_free_good.fg_component.value
          skus.lines.forEach((skul)=>{
            console.log("temp_fg_select==>>",temp_fg_select)
            let promoData = skul.so_line_promo[0];
            if (promoData) { // jika so line promo kosong di skip aja biar g error (data prod masih ada yang kosong)
              var discount_string = this.getDiscTypeString(promoData.discount_type);
              var base_string = this.getBaseString(promoData.base);
              console.log("discount_string", discount_string)
              console.log("base_string", base_string)

              if (parseInt(promoData.discount_type)==disc_type_choice) {
                console.log("=======2289")
                let fg_filter =[]
                fg_filter = this.table.free_goods.filter((el)=>{
                  return promoData.promotion_item_req_id == el.promotion_item_req_id
                })

                if (fg_filter.length>0) {

                  console.log("2297")
                  temp_data_fg = fg_filter[0]
                  temp_fg_select.sku_code=skul.sku_code
                  temp_fg_select.item_id=skul.item_id
                  temp_fg_select.item_name=skul.item_name
                  temp_fg_select.item_unit=skul.unit
                  temp_fg_select.item_unit_id=skul.item_unit_id
                  temp_fg_select.text="["+skul.sku_code+"] "+ skul.item_name
                  temp_fg_select.qty=skul.qty
                  temp_fg_select.price=skul.price
                  temp_fg_select.discount=skul.qty*skul.price
                  temp_fg_select.cap=promoData.cap
                  temp_fg_select.capped=promoData.capped
                  temp_fg_select.cogs=skul.cogs.toFixed(2)
                  temp_data_fg.fg_component.optionValue.push = temp_fg_select

                }else{
                  console.log("=======2314")

                  temp_fg_select.sku_code=skul.sku_code
                  temp_fg_select.item_id=skul.item_id
                  temp_fg_select.item_name=skul.item_name
                  temp_fg_select.item_unit=skul.unit
                  temp_fg_select.item_unit_id=skul.item_unit_id
                  temp_fg_select.text="["+skul.sku_code+"] "+ skul.item_name
                  temp_fg_select.qty=skul.qty
                  temp_fg_select.price=skul.price
                  temp_fg_select.discount=skul.qty*skul.price
                  temp_fg_select.cap=promoData.cap
                  temp_fg_select.capped=promoData.capped
                  temp_fg_select.cogs=skul.cogs.toFixed(2)

                  temp_data_fg.discount_type = promoData.discount_type
                  temp_data_fg.discount_string = discount_string
                  temp_data_fg.promotion_type = promoData.promotion_type
                  temp_data_fg.promotion_code = promoData.promotion_code
                  temp_data_fg.promotion_id = promoData.promotion_id
                  temp_data_fg.promotion_item_req_id = promoData.promotion_item_req_id
                  temp_data_fg.promotion_sequence = promoData.promotion_sequence
                  temp_data_fg.promotion_step_id = promoData.promotion_step_id
                  temp_data_fg.line = i
                  temp_data_fg.qty = promoData.qty
                  temp_data_fg.to = promoData.to
                  temp_data_fg.from = promoData.from
                  temp_data_fg.base = promoData.base
                  temp_data_fg.base_amount = promoData.base_amount
                  temp_data_fg.base_string = base_string
                  temp_data_fg.fg_component.optionValue.push(temp_fg_select)
                  temp_data_fg.fg_component.value = this.temp_free_good.fg_component.value;
                  temp_data_fg.fg_component.disabled = false
                }
              }else{
                console.log("=======2351")
                console.log("=======2351 temp_fg_select", temp_fg_select)
                temp_fg_select.sku_code=skul.sku_code
                temp_fg_select.item_id=skul.item_id
                temp_fg_select.item_name=skul.item_name
                temp_fg_select.item_unit=skul.unit
                temp_fg_select.item_unit_id=skul.item_unit_id
                temp_fg_select.text="["+skul.sku_code+"] "+ skul.item_name
                temp_fg_select.qty=skul.qty
                temp_fg_select.price=skul.price
                temp_fg_select.discount=skul.qty*skul.price
                temp_fg_select.cap=promoData.cap
                temp_fg_select.capped=promoData.capped
                temp_fg_select.cogs=skul.cogs.toFixed(2)

                temp_data_fg.discount_type = promoData.discount_type
                temp_data_fg.discount_string = discount_string
                temp_data_fg.promotion_type = promoData.promotion_type
                temp_data_fg.promotion_code = promoData.promotion_code
                temp_data_fg.promotion_id = promoData.promotion_id
                temp_data_fg.promotion_item_req_id = promoData.promotion_item_req_id
                temp_data_fg.promotion_sequence = promoData.promotion_sequence
                temp_data_fg.promotion_step_id = promoData.promotion_step_id
                temp_data_fg.line = i
                temp_data_fg.qty = promoData.qty
                temp_data_fg.to = promoData.to
                temp_data_fg.from = promoData.from
                temp_data_fg.base = promoData.base
                temp_data_fg.base_amount = promoData.base_amount
                temp_data_fg.base_string = base_string
                temp_data_fg.fg_component.optionValue.push(temp_fg_select)
                temp_data_fg.fg_component.value = {
                  sku_code: skul.sku_code,
                  item_id: skul.item_id,
                  item_name: skul.item_name,
                  item_unit: skul.unit,
                  item_unit_id: skul.item_unit_id,
                  text: "["+skul.sku_code+"] "+ skul.item_name,
                  qty: skul.qty,
                  price: skul.price,
                  discount: skul.qty*skul.price,
                  cap: promoData.cap,
                  capped: promoData.capped,
                  cogs: skul.cogs.toFixed(2),
                };
                temp_data_fg.fg_component.disabled = true
              }
              console.log("temp_data_fg.line=>",temp_data_fg.line,", i=>",i)
              console.log("temp_data_fg",temp_data_fg)
              fgHolder.push(temp_data_fg)
            }
          });
          // end setting Line freegood
        }


        console.log("__tempData__", tempData)
        console.log("__tempData__", tempData)
        console.log("fgHolder", fgHolder)
        console.log("this.isExistSkuCombo", this.isExistSkuCombo)
        

        if (tempData != null) {
          tempData.isBlink = false;
          tempData.recalculate = false;
          data.push(tempData)
        }
      });
      this.table.data = data;
      this.table.free_goods = fgHolder;
      this.manual_calc_resp = manual_calc_resp;
      console.log("this.table.data", this.table.data)
      console.log("this.table.free_goods", this.table.free_goods)
      if (this.table.free_goods.length>0) {
        this.setPromoDiscountTypeLowestPrice()
        this.existing_fg = true;
      } else {
        this.existing_fg = false;
      }
    },
    getItem(search,comp) {
      return new Promise((resolve, reject) => {
        let idx =0
        if (comp) {
            idx = comp[0].parentId-1;
            this.table.data[idx].formInputs[0].isLoading =true
        }else{
            this.table.data.forEach((dt,i) => {
              this.table.data[i].formInputs[0].isLoading =true
            });
        }
        let searchItem =""
        if(search){
            searchItem = search
        }
        this.$http
        .get("/api/oms/v1/sfa-sales-order/get-item-available", { params: { limit: 40, offset: 0, search: searchItem, }})
        .then((resp) => {
            this.table.data[idx].formInputs[0].isLoading =false
            if (resp.code == 200) {
                let data =[]
                resp.data.records.forEach(dt=>{
                    data.push({
                        id:dt.item_id,
                        item_id:dt.item_id,
                        code:dt.sku_code,
                        text:'[' + dt.sku_code + '] ' + dt.item_name,
                        sku_name:dt.item_name
                    })
                })
                if (comp) {
                    this.table.data[idx].formInputs[0].optionValue = data
                    }else{
                    this.table.data.forEach((dt,i) => {
                        this.table.data[i].formInputs[0].optionValue  = data
                        this.table.data[i].formInputs[0].isLoading    = false
                    });
                }
                resolve(true);
            } else {
                console.log(resp);
                reject(false);
            }
        });
      });
    },
    getAllDataPricingGroup() {
        return new Promise((resolve, reject) => {
          this.$http.get("api/oms/v1/sfa-sales-order/pricing-group", {
            params: {
              order: "name",
              sort: "asc",
            },
          }).then((resp) => {
              if (resp.status == "success") {
                  this.pricingGroup.options = resp.data.records;
                  this.pricingGroup.all_options = resp.data.records;
                  resolve(true);
              } else {
                console.log(resp)
                reject(false);
              }
          });
        });
      },
    getOptionPaymentTerm() {
      return new Promise((resolve, reject) => {
        this.$http.get("api/oms/v1/sfa-sales-order/payment-term", {
          params: {
            order: 'name',
            sort: 'asc',
          }
        }).then(resp => {
            if (resp.code == 200) {
                this.paymentTerm.all_options = resp.data.records;
                this.paymentTerm.selected = null;
                // Set Payment Term
                console.log("this.salesOrder.Customer.PricingGroupID", this.salesOrder.Customer.PricingGroupID)
                this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
                    return v.id == this.salesOrder.Customer.PaymentTermID;
                });
                this.paymentTerm.selected = this.paymentTerm.selected[0];
                resolve(true);
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Payment Term option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                console.log(resp);
                reject(false);
            }
        });
      });
    },
    expandDetail(i1,i2){
      console.log(i1,i2)
      if (this.table.data.length>0&&this.table.data[i1].list_price.length>0) {
        this.table.data[i1].list_price[i2].is_expand = !this.table.data[i1].list_price[i2].is_expand
        if (this.table.data[i1].list_price[i2].is_expand) {
          this.table.data[i1].rowSpanPlus++
        }else if(this.table.data[i1].rowSpanPlus>1){
          this.table.data[i1].rowSpanPlus--
        }
      }
    },
    expandDetailCombo(i1,i2){
      console.log("expandDetailCombo")
      console.log(i1,i2)
      if (this.table.data.length>0&&this.table.data[i1].data_combo.length>0) {
        console.log("this.table.data[i1].data_combo[i2].is_expand")
        console.log(this.table.data[i1].data_combo[i2].is_expand)
        this.table.data[i1].data_combo[i2].is_expand = !this.table.data[i1].data_combo[i2].is_expand
        if (this.table.data[i1].data_combo[i2].is_expand && this.table.data[i1].data_combo[i2].rowSpanPlusCombo == 1) {
          this.table.data[i1].data_combo[i2].rowSpanPlusCombo++
          this.comboRowSpanPlus++
        }else if(this.table.data[i1].data_combo[i2].rowSpanPlusCombo>1){
          this.table.data[i1].data_combo[i2].rowSpanPlusCombo--
          this.comboRowSpanPlus--
        }
        console.log("After ? is_expand")
        console.log(this.table.data[i1].data_combo[i2].is_expand)
      }
    },
    showDetailDisc(data,subtotal="0", i, i2 ) {
      console.log("data", data)
      data.promo_calculation.forEach(pc => {
        console.log("typeof pc.order_line", typeof pc.order_line)
        console.log("typeof pc.order_line_after_discount", typeof pc.order_line_after_discount)
        if (typeof pc.order_line != "string" && typeof pc.order_line_after_discount != "string") {
          pc.order_line = pc.order_line.toFixed(2);
          pc.order_line_after_discount = pc.order_line_after_discount.toFixed(2);
        }
        let base_string, disc_type_string;
        if (pc.base_string == undefined) {
          switch (pc.base) {
            case 1:  base_string = "quantity"; break;
            case 2:  base_string = "value"; break;
            default: base_string = ""; break;
          }
          pc.base_string = base_string;
        }
        // 1: percentage; 2: amount; 3: fix amount; 4: fix item; 5: lowest price; 6: sku; 7: merchandise; 8: lowest price;
        if (pc.discount_string == undefined) {
          switch (pc.discount_type) {
            case 1:  disc_type_string = "percentage"; break;
            case 2:  disc_type_string = "amount"; break;
            case 3:  disc_type_string = "fix amount"; break;
            case 4:  disc_type_string = "fix item"; break;
            case 5:  disc_type_string = "lowest price"; break;
            case 6:  disc_type_string = "sku"; break;
            case 7:  disc_type_string = "merchandise"; break;
            default: disc_type_string = ""; break;
          }
          pc.discount_string = disc_type_string;
        }
      });
      this.detailDisc = true;
      this.selectedDiscount = data;
      this.pricingCode = data.price_rule_code
      this.pricing = data.price
      this.sku = data.item_name+" "+data.unit
      this.subtotal = subtotal;
      this.rounding = data.rounding;
      console.log("this.table.data", this.table.data)
      this.detailPricing = {
        "pricing_code": this.table.data[i].list_price[i2].price_rule_code,
        "price": this.table.data[i].list_price[i2].price,
        "sub_total": this.table.data[i].subtotal.price[i2],
        "tax": this.table.data[i].subtotal.tax[i2],
        "rounding": this.table.data[i].list_price[i2].rounding,
        "nett": this.table.data[i].subtotal.price[i2]-this.table.data[i].list_price[i2].discount+this.table.data[i].list_price[i2].rounding,
        "gross_up": this.table.data[i].list_price[i2].gross_up,
        "dpp": this.table.data[i].list_price[i2].dpp ? this.table.data[i].list_price[i2].dpp : ( this.table.data[i].subtotal.price[i2] - this.table.data[i].list_price[i2].discount + this.table.data[i].list_price[i2].gross_up),
        "grand_total": this.table.data[i].grand_total[i2],
        "sku": data.item_name+" - "+data.unit
      }
    },
  },
  filters: {
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  
  mounted(){
    // console.log("userLogin : ", this.$userLogin);
    // console.log(this.salesOrder)
    // console.log(this.selectedTerritory)
    // console.log(this.selectedDistChannel)
    let id = parseInt(this.$route.params.id)
    if(id != undefined) {
      this.$vs.loading();
      Promise.all([
        this.getItem(),
        this.getAllDataPricingGroup(),
        this.getOptionPaymentTerm(),
        this.getData(),
      ]).then((resp) => {
        console.log(resp);
        console.log("response",resp);
        if(this.salesOrder.Category == "ZDCBD"){
          this.getCustDeposit_( parseInt(this.salesOrder.Customer.DefaultAttribute.ID));
        }
        let is_success = true;
        resp.forEach(r => {
          if( !r ){ is_success = r; }
        });
        if (is_success) {
          // disini kalau success semua persiapan datanya

          console.log("this.salesOrder", this.salesOrder);
  
          // this.dispTerritory= this.salesOrder.Territory.Code + " " + this.salesOrder.Territory.Name;
          // this.dispDistChannel = this.salesOrder.Customer.CustomerCategory.Name + " - " + this.salesOrder.Customer.CustomerCategory.Classification;
          // this.dispSalesChannel = this.salesOrder.SalesChannel.Code + " " + this.salesOrder.SalesChannel.Name;
          // this.resetSalesOrder = Object.assign({}, this.salesOrder);
          // this.salesOrder.Date = new Date(this.salesOrder.Date)
          // this.salesOrder.DueDate = new Date(this.salesOrder.DueDate)
          // this.salesOrder.RequestDeliveryDate = new Date(this.salesOrder.RequestDeliveryDate)
          // this.salesOrder.PoReferenceDate = this.salesOrder.PoReferenceDate != null ? new Date(this.salesOrder.PoReferenceDate) : null;
          // this.baseUrl = this.baseUrl + "/" + this.salesOrder.DefaultAttribute.ID;
  
          // this.salesOrder.Customer.DisplayName = this.salesOrder.Customer.Code + ' - ' + this.salesOrder.Customer.Name
  
          // this.external = false; // sementara dibikin true, belom ada object saleschannel di api SO
  
          this.disable_form = false;
          this.update = true;
  
          console.log("this.salesOrder", this.salesOrder);
          this.setDataSO();
  
        } else {
          console.log(resp);
        }
        this.$vs.loading.close();
      });
    } else {
        console.log("masuk else lho")
        this.update = false;
        this.disable_form = true;
        console.log("this.update", this.update)
    }
  
    console.log("this.salesOrder", this.salesOrder);
    console.log("this.salesOrder.Customer", this.salesOrder.Customer);
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.badge.badge-success {
  background-color: #28c76f;
}
.badge {
  font-weight: 400;
  color: #fff;
  background-color: #7367f0;
}
.badge-success {
  color: #fff;
  background-color: #28c76f;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
.table-expend:hover,.table-expend-combo:hover td.combo-hover{
    background: #e9e9e9;
    cursor: pointer;
}
.table-expend:active,.table-expend-combo td.combo-hover:active{
    background: #e7e5e5;
}
</style>
